



































































































































































































































































import { COUNTRIES } from '@/shared/constants'
import { IUser, User, UserRole } from '@/shared/model/user.model'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'UserWizard',
  components: {
    ImageUpload: () => import('../_components/ImageUpload.vue'),
  },

  data () {
    return {
      user: new User() as IUser,
      roles: [
        {
          icon: 'mdi-code-tags',
          name: UserRole.admin,
        },
        {
          icon: 'mdi-laptop',
          name: UserRole.user,
        },
      ],
      image: null as null | string,
      imageContentType: null as null | string,
      countries: COUNTRIES,
      tab: 0,
      tabs: [this.$t('user').toString(), this.$t('rights').toString(), this.$t('details').toString()],
      customRules: 'required|checkusername',
      birthdayDatePickerVisible: false,
      birthdayVal: undefined,
      customEmailRules: 'email|checkemail',
    }
  },
  computed: {
    ...mapGetters({
      updateUserError: 'userStore/getError',
    }),
    maxDate (): any {
      const now = new Date()
      const date10YearsAgo = new Date()
      date10YearsAgo.setFullYear(now.getFullYear() - 10)
      return date10YearsAgo.toISOString()
    },
  },
  watch: {
    birthdayDatePickerVisible (value) {
      value &&
        this.$nextTick(() => {
          const picker = this.$refs.pickerInDialog as any
          if (picker && picker.internalActivePicker) {
            picker.internalActivePicker = 'YEAR'
          }
        })
    },
  },

  methods: {
    ...mapActions('userStore', {
      createUser: 'createItem',
    }),
    availableSteps (valid: any) {
      const steps = [0]

      if (valid && this.user.username && this.user.metadata && this.user.metadata.lastName && this.user.metadata.firstName) {
        steps.push(1)
      }

      if (valid && steps.includes(1) && this.user.roles && this.user.roles.length > 0) {
        steps.push(2)
      }

      if (valid && this.user.metadata && this.user.metadata.address && this.user.metadata.address.country && steps.includes(2)) {
        steps.push(3)
      }

      return steps
    },
    async next (valid: any) {
      if (!valid) return

      if (this.tab === this.tabs.length - 1) {
        await this.createUser({ item: this.user })
        if (this.updateUserError) {
          if (this.updateUserError.response && this.updateUserError.response.data === 'user already exists') {
            this.$toast.error(this.$t('dialog.user-already-exists').toString())
          } else {
            this.$toast.error(this.$t('dialog.an-unexpected-error-occured').toString())
          }
        } else {
          this.$toast(this.$t('dialog.save-user-success').toString())
          this.$emit('wizardfinished')
        }
      } else {
        this.tab++
      }
    },
    selectDate () {
      this.birthdayDatePickerVisible = false
      if (this.user.metadata) this.user.metadata.birthday = this.birthdayVal
    },
    setImage: function (blobContent: any) {
      if (blobContent && blobContent.image) {
        this.image = blobContent.image
        if (blobContent.imageContentType) {
          this.imageContentType = blobContent.imageContentType
        } else {
          this.imageContentType = null
        }
        if (this.user.metadata) {
          this.user.metadata.image = blobContent.image
        }
      }
    },
  },
})
